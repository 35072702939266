<template>
    <section class="invoice-add-wrapper">
        <b-breadcrumb class="mb-1">
            <b-breadcrumb-item href="/">
                <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
                Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item v-on:click="goBack">Facture d'acompte</b-breadcrumb-item>
            <b-breadcrumb-item active>Nouvelle Facture</b-breadcrumb-item>
        </b-breadcrumb>
        <b-row class="invoice-add">
            <b-col cols="12">
                <b-card>
                    <b-card no-body class="invoice-preview-card">
                        <b-card-body class="invoice-padding pb-0">
                            <div class="
                  d-flex
                  justify-content-between
                  flex-md-row flex-column
                  invoice-spacing
                  mt-0
                ">
                                <!-- Header: Left Content -->
                                <div>
                                    <div class="logo-wrapper">
                                        <b-img :src="enterprise.logo" width="64" alt="placeholder"></b-img>
                                        <h3 class="text-primary invoice-logo">
                                            {{ enterprise.name }}
                                        </h3>
                                    </div>

                                    <b-card-text class="mb-25">
                                        {{ enterprise.address }} - {{ enterprise.country.name }}
                                    </b-card-text>
                                </div>

                                <!-- Header: Right Content -->
                                <div class="invoice-number-date mt-md-0 mt-2">
                                    <div class="
                      d-flex
                      align-items-center
                      justify-content-md-end
                      mb-1
                    ">
                                        <span class="title"> Date: </span>
                                        <flat-pickr v-model="today" class="form-control invoice-edit-input" disabled />
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                        <hr class="invoice-spacing" />
                        <validation-observer ref="form">
                            <b-card-body class="invoice-padding pt-0">
                                <b-row class="invoice-spacing">
                                    <b-col cols="12" xl="6" class="mb-lg-1">
                                        <h6 class="mb-2">Client :</h6>
                                        <v-select placeholder="Veuillez choisir un client" v-model="customerMultiSelect"
                                            :options="customers" label="name" input-id="invoice-data-client"
                                            :clearable="false">
                                            <template #list-header>
                                                <li v-b-toggle.sidebar-invoice-add-new-customer class="
                              add-new-client-header
                              d-flex
                              align-items-center
                              my-50
                            ">
                                                    <feather-icon icon="PlusIcon" size="16" />
                                                    <span class="align-middle ml-25">Ajouter un client</span>
                                                </li>
                                            </template>
                                        </v-select>
    
                                        <div class="mt-1" v-if="myCustomer.name != ''">
                                            <b-card-text class="mb-25"><b>Nom :</b> {{ myCustomer.name }}
                                            </b-card-text>
                                            <b-card-text class="mb-25">
                                                <b>Adresse :</b> {{ myCustomer.address }}
                                            </b-card-text>
                                            <b-card-text class="mb-25"><b>Téléphone :</b> {{ myCustomer.telephone }}
                                            </b-card-text>
                                            <b-card-text class="mb-0"><b>Email :</b> {{ myCustomer.email }}</b-card-text>
                                        </div>
                                    </b-col>
    
                                    <!-- Col: Payment Details -->
                                </b-row>
    
                                <!-- <div class="col-md-3" style="float: right">
                                    <validation-provider #default="{ errors }" :rules="'required'" name="TVA">
                                        <b-form-group label="TVA(%)">
                                            <b-form-input type="number" v-model="advancePayment.tva" />
                                            <small class="text-danger"> {{ errors[0] }} </small>
                                        </b-form-group>
                                    </validation-provider>
                                </div> -->
                            </b-card-body>
    
                            <b-card-body class="">
                                <div class="mb-2 table-responsive">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Désignation</th>
                                                <th scope="col">Prix</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <validation-provider #default="{ errors }" :rules="'required'"
                                                        name="Désignation">
                                                        <b-form-group>
                                                            <b-form-input :disabled="!isDisable" id="item-name" type="text"
                                                                v-model="advancePayment.designation"
                                                                placeholder="Veuillez saisir la désignation" />
                                                            <small class="text-danger"> {{ errors[0] }} </small>
                                                        </b-form-group>
                                                    </validation-provider>
    
                                                </td>
                                                <td>
                                                    <validation-provider #default="{ errors }" :rules="'required'"
                                                        name="Montant">
                                                        <b-form-group>
                                                            <b-form-input :disabled="!isDisable"
                                                                v-model="advancePayment.total" id="price" type="number" />
                                                            <small class="text-danger"> {{ errors[0] }} </small>
                                                        </b-form-group>
                                                    </validation-provider>
    
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </b-card-body>
    
                            <b-card-body class="invoice-padding pb-0 mt-2">
                                <b-row>
                                    <b-col cols="12" md="6" class="mt-md-0 mt-3 d-flex align-items-center" order="2"
                                        order-md="1">
                                    </b-col>
    
                                    <!-- Col: Total -->
                                    <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1"
                                        order-md="2">
                                        <div class="invoice-total-wrapper">
                                            <hr class="my-50" />
                                            <div class="invoice-total-item">
                                                <p class="invoice-total-title">Total HT :</p>
                                                <p class="invoice-total-amount">{{ totalHT() }} €</p>
                                            </div>
                                            <!-- <div class="invoice-total-item">
                                                <p class="invoice-total-title">TVA:</p>
                                                <p class="invoice-total-amount">{{ totalTVA() }} €</p>
                                            </div>
                                            <div class="invoice-total-item">
                                                <p class="invoice-total-title">Total TTC:</p>
                                                <p class="invoice-total-amount">{{ totalTTC() }} €</p>
                                            </div> -->
                                        </div>
                                    </b-col>
                                </b-row>
                                <!-- Spacer -->
                                <hr class="invoice-spacing" />
                            </b-card-body>
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                                class="mb-75 d-flex align-items-center justify-content-center" block :disabled="loading"
                                @click="createAdvancePayment">
                                <b-spinner v-if="loading" type="grow" small></b-spinner>
                                Enregistrer
                            </b-button>
                        </validation-observer>
                        <!-- Note -->
                    </b-card>
                </b-card>
            </b-col>
        </b-row>
        <invoice-sidebar-add-new-customer />
    </section>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { VBToggle } from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapState, mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import InvoiceSidebarAddNewCustomer from '@/views/admin/ModuleInvoice/Create/InvoiceSidebarAddNewCustomer.vue';

export default {
    data() {
        var today = new Date();
        const now = today;
        const myDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const minDate = new Date(myDate);
        minDate.setMonth(0);
        minDate.setYear(minDate.getFullYear());
        minDate.setDate(1);

        const maxDate = new Date();
        maxDate.setMonth(11);
        maxDate.setYear(maxDate.getFullYear());
        maxDate.setDate(31);

        return {
            min: minDate,
            max: maxDate,
            loading: false,
            errors: "",
            theSum: 0,
            isDisable: false,
            enterprise: {},
            today,
            customerMultiSelect: null,
            myCustomer: {
                name: "",
                address: "",
                telephone: 0,
                email: "",
            },
            advancePayment: {
                customer_id: 0,
                designation: "",
                total: 0,
                tva: 20,
            },
            clients: {},
        };
    },

    components: {
        flatPickr,
        vSelect,
        Multiselect,
        ToastificationContent,
        InvoiceSidebarAddNewCustomer,
    },

    directives: {
        Ripple,
        "b-toggle": VBToggle,
    },

    computed: {
        ...mapState("enterprise", { customers: (state) => state.clients }),
    },
    watch: {
        customerMultiSelect(val) {
            this.myCustomer = this.customerMultiSelect;
            if (this.myCustomer.name != "") {
                this.isDisable = true;
                this.advancePayment.customer_id = this.myCustomer.id;
            }
        },
    },

    mounted() {
        this.getInfo().then((r) => {
            this.enterprise = r.data.data;
        });
        this.fetchCustomers();
    },

    methods: {
        ...mapActions("enterprise", ["fetchCustomers", "addCustomer"]),
        ...mapActions("enterprise", ["getInfo"]),
        ...mapActions("advancePayments", ["addAdvancePayment", 'allAdvancePayments']),

        goBack() {
            history.back();
        },

        editThisPrestation(data) {
            this.isPrestationEditMode = true;
        },

        totalHT() {
            return Number(this.advancePayment.total)
                .toFixed(0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },

        totalTVA() {
            // calculate the total tva
            let tva = this.advancePayment.tva;
            let total = Number(this.advancePayment.total);
            let tvaAmount = (total * tva) / 100;
            return tvaAmount
                .toFixed(0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },

        totalTTC() {
            let tva = this.advancePayment.tva;
            let total = Number(this.advancePayment.total);
            let tvaAmount = (total * tva) / 100;
            let totalTTC = total + tvaAmount;
            return totalTTC
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },

        customerName({ name }) {
            return name;
        },

        async createAdvancePayment() {
            const valid = await this.$refs.form.validate();
            if(!valid) return;
            
            this.loading = true;
            this.addAdvancePayment(this.advancePayment).then(response => {
                this.loading = false;
                this.goBack();
            });
        },

        clearForm() {
            this.service = this.serviceDefault;
            this.$nextTick().then((response) => {
                this.$refs.serviceForm.reset();
            });
        },
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.invoice-add-wrapper {
    .add-new-client-header {
        padding: $options-padding-y $options-padding-x;
        color: $success;

        &:hover {
            background-color: rgba($success, 0.12);
        }
    }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
    background-color: $product-details-bg;
}

.form-item-action-col {
    width: 27px;
}

.repeater-form {
    // overflow: hidden;
    transition: 0.35s height;
}

// .v-select {
//   &.item-selector-title,
//   &.payment-selector {
//     background-color: #fff;

//     .dark-layout & {
//       background-color: unset;
//     }
//   }
// }

.dark-layout {
    .form-item-section {
        background-color: $theme-dark-body-bg;

        .row .border {
            background-color: $theme-dark-card-bg;
        }
    }
}

.ky-btn {
    cursor: pointer;
}
</style>
